@font-face {
  font-family: 'Avallon All Caps';
  src: url('/assets/fonts/Avallon/AvallonAllCaps.eot');
  src: local('Avallon All Caps'), local('AvallonAllCaps'),
      url('/assets/fonts/Avallon/AvallonAllCaps.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/Avallon/AvallonAllCaps.woff2') format('woff2'),
      url('/assets/fonts/Avallon/AvallonAllCaps.woff') format('woff'),
      url('/assets/fonts/Avallon/AvallonAllCaps.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avallon';
  src: url('/assets/fonts/Avallon/Avallon.eot');
  src: local('Avallon'),
      url('/assets/fonts/Avallon/Avallon.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/Avallon/Avallon.woff2') format('woff2'),
      url('/assets/fonts/Avallon/Avallon.woff') format('woff'),
      url('/assets/fonts/Avallon/Avallon.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avallon Alt';
  src: url('/assets/fonts/Avallon/AvallonAlt.eot');
  src: local('Avallon Alt'), local('AvallonAlt'),
      url('/assets/fonts/Avallon/AvallonAlt.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/Avallon/avallonalt-webfont.woff2') format('woff2'),
      url('/assets/fonts/Avallon/avallonalt-webfont.woff') format('woff'),
      url('/assets/fonts/Avallon/avallonalt-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Mundo Sans Std";
  src: url("/assets/fonts/Mundo%20Sans%20Std/MundoSansStd.otf") format("opentype");
}
@font-face {
  font-family: "Mundo Sans Italic";
  font-style: italic;
  src: url("/assets/fonts/Mundo%20Sans%20Std/MundoSansStd.otf") format("opentype");
}
@font-face {
  font-family: "Mundo Sans Bold";
  font-weight: 700;
  src: url("/assets/fonts/Mundo%20Sans%20Std/MundoSansStd-Bold.otf") format("opentype");
}
@font-face {
  font-family: "Mundo Sans Black";
  font-weight: 900;
  src: url("/assets/fonts/Mundo%20Sans%20Std/MundoSansStd-Black.otf") format("opentype");
}
@font-face {
  font-family: "Mundo Sans Light";
  font-weight: 200;
  src: url("/assets/fonts/Mundo%20Sans%20Std/MundoSansStd-Light.otf") format("opentype");
}
@font-face {
  font-family: "Mundo Sans Light Italic";
  font-weight: 200;
  font-style: italic;
  src: url("/assets/fonts/Mundo%20Sans%20Std/MundoSansStd-LightItalic.otf") format("opentype");
}
@font-face {
  font-family: "Mundo Sans Extra Light";
  font-weight: 100;
  src: url("/assets/fonts/Mundo%20Sans%20Std/MundoSansStd-ExtraLight.otf") format("opentype");
}
@font-face {
  font-family: "Amsi Pro Narrow";
  src: url("/assets/fonts/Amsi%20Pro%20Complete%20Set/Stawix%20-%20AmsiProNarw-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Amsi Pro Narrow Italic";
  font-style: italic;
  src: url("/assets/fonts/Amsi%20Pro%20Complete%20Set/Stawix%20-%20AmsiProNarw-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Amsi Pro Narrow Bold";
  font-weight: 700;
  src: url("/assets/fonts/Amsi%20Pro%20Complete%20Set/Stawix%20-%20AmsiProNarw-Bold.otf") format("opentype");
}
@font-face {
  font-family: "Amsi Pro Narrow Black";
  font-weight: 900;
  src: url("/assets/fonts/Amsi%20Pro%20Complete%20Set/Stawix%20-%20AmsiProNarw-Black.otf") format("opentype");
}
@font-face {
  font-family: "Amsi Pro Narrow Light";
  font-weight: 200;
  src: url("/assets/fonts/Amsi%20Pro%20Complete%20Set/Stawix%20-%20AmsiProNarw-Light.otf") format("opentype");
}
@font-face {
  font-family: "Amsi Pro Narrow Light Italic";
  font-weight: 200;
  font-style: italic;
  src: url("/assets/fonts/Amsi%20Pro%20Complete%20Set/Stawix%20-%20AmsiProNarw-LightItalic.otf") format("opentype");
}
@font-face {
  font-family: "Amsi Pro Narrow Extra Light";
  font-weight: 100;
  src: url("/assets/fonts/Amsi%20Pro%20Complete%20Set/Stawix%20-%20AmsiProNarw-XLight.otf") format("opentype");
}

@font-face {
  font-family: "Amsi Pro Condensed";
  src: url("/assets/fonts/Amsi%20Pro%20Complete%20Set/Stawix%20-%20AmsiProCond-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Amsi Pro Condensed Italic";
  font-style: italic;
  src: url("/assets/fonts/Amsi%20Pro%20Complete%20Set/Stawix%20-%20AmsiProCond-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Amsi Pro Condensed Bold";
  font-weight: 700;
  src: url("/assets/fonts/Amsi%20Pro%20Complete%20Set/Stawix%20-%20AmsiProCond-Bold.otf") format("opentype");
}
@font-face {
  font-family: "Amsi Pro Condensed Black";
  font-weight: 900;
  src: url("/assets/fonts/Amsi%20Pro%20Complete%20Set/Stawix%20-%20AmsiProCond-Black.otf") format("opentype");
}
@font-face {
  font-family: "Amsi Pro Condensed Light";
  font-weight: 200;
  src: url("/assets/fonts/Amsi%20Pro%20Complete%20Set/Stawix%20-%20AmsiProCond-Light.otf") format("opentype");
}
@font-face {
  font-family: "Amsi Pro Condensed Light Italic";
  font-weight: 200;
  font-style: italic;
  src: url("/assets/fonts/Amsi%20Pro%20Complete%20Set/Stawix%20-%20AmsiProCond-LightItalic.otf") format("opentype");
}
@font-face {
  font-family: "Amsi Pro Condensed Extra Light";
  font-weight: 100;
  src: url("/assets/fonts/Amsi%20Pro%20Complete%20Set/Stawix%20-%20AmsiProCond-XLight.otf") format("opentype");
}

@font-face {
  font-family: "Amsi Pro";
  src: url("/assets/fonts/Amsi%20Pro%20Complete%20Set/Stawix%20-%20AmsiPro-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Amsi Pro Italic";
  font-style: italic;
  src: url("/assets/fonts/Amsi%20Pro%20Complete%20Set/Stawix%20-%20AmsiPro-Italic.otf") format("opentype");
}
@font-face {
  font-family: "Amsi Pro Bold";
  font-weight: 700;
  src: url("/assets/fonts/Amsi%20Pro%20Complete%20Set/Stawix%20-%20AmsiPro-Bold.otf") format("opentype");
}
@font-face {
  font-family: "Amsi Pro Black";
  font-weight: 900;
  src: url("/assets/fonts/Amsi%20Pro%20Complete%20Set/Stawix%20-%20AmsiPro-Black.otf") format("opentype");
}
@font-face {
  font-family: "Amsi Pro Light";
  font-weight: 200;
  src: url("/assets/fonts/Amsi%20Pro%20Complete%20Set/Stawix%20-%20AmsiPro-Light.otf") format("opentype");
}
@font-face {
  font-family: "Amsi Pro Light Italic";
  font-weight: 200;
  font-style: italic;
  src: url("/assets/fonts/Amsi%20Pro%20Complete%20Set/Stawix%20-%20AmsiPro-LightItalic.otf") format("opentype");
}
@font-face {
  font-family: "Amsi Pro Extra Light";
  font-weight: 100;
  src: url("/assets/fonts/Amsi%20Pro%20Complete%20Set/Stawix%20-%20AmsiPro-XLight.otf") format("opentype");
}

$grid-breakpoints: (
  xs: 0, // Phone (0px - 575px)
  sm: 576px, // Phablet (576px - 767px)
  md: 768px, // Tablet vertical (768px - 991px)
  lg: 992px, // Tablet horizontal, Desktop (992px and above)
  xl: 1200px
);

:root {

  --bw-opaque-primary: rgba(255, 255, 255, 0.9);
  --bw-opaque-secondary: rgba(255, 255, 255, 0.3);

  --ion-font-family:  "Mundo Sans Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  ion-toolbar {
    --background: none;
    --background-color: transparent;
  }

  ion-app > ion-content {
    --background: none;
  }
  ion-card-subtitle {
    font-family: "Amsi Pro Condensed Black", "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 0px;
    line-height: initial;
    font-size: 24px;
    --color: var(--ion-background-color);
  }
  ion-card-title {
    font-weight: bold;
    font-size: 18px;
    --color: var(--ion-background-color);
  }

  ion-card {
    --background: var(--ion-text-color);
    --color: var(--ion-background-color);
  }

  ion-router-link {
    --color: var(--ion-background-color);
  }

  ion-button::part(native) {
    --color: var(--ion-text-color);
  }

  ion-item {
    &.item-has-focus {
      --border-width: 0 !important;
    }
  }

  --swiper-navigation-size: 64px;

  ion-modal {
    --background: var(--bw-opaque-primary);
    ion-title {
      --color: var(--ion-background-color);
    }
    ion-toolbar {
      ion-icon {
        margin-right: 12px;
        cursor: pointer;
      }
    }
    ion-content {
      --background: none;
      ion-item {
        --background: none;
      }
    }
    .search-content {

      ion-button {
        margin-right: -16px !important;
      }
    }
  }
  ion-breadcrumb::part(native) {
    cursor: pointer;
    padding-inline: 0;
  }
  ion-breadcrumb:last-of-type::part(native) {
    cursor: text;
  }
  ion-breadcrumb::part(separator) {
    margin-inline: 4px;
  }

  app-dynamic-routes {
    display: contents;
  }

  app-product {
    ion-card {
      @media (min-width: map-get($grid-breakpoints, md)) {
        max-width: 768px;
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }
  }

  [trigger="openSearchModal"] {
    ion-card {
      cursor: pointer;
    }
  }

  bw-product-card {
    ion-img {
      cursor: pointer;
    }
    [name="calendar-outline"] {
      cursor: pointer;
    }
    ion-radio {
      margin-right: 16px;
    }
  }


  ion-grid {
    bw-product-card {
      ion-card {
        margin-bottom: 3.5rem;
        height: 100%;
      }

      ion-toolbar {
        position: absolute;
        bottom: 0;
      }
    }
  }

  .login-modal {
    ion-label {
      font-family: "Mundo Sans Std", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
      color: var(--ion-background-color);
    }
    ion-input {
      color: var(--ion-background-color);
    }
    ion-segment-button {
      color: var(--ion-background-color) !important;
      font-weight: bold;
      &::part(native) {
        color: var(--ion-background-color) !important;
      }
    }
    ion-checkbox {
      margin-right: 1rem;
    }
  }

  bw-product-detail, bw-product-review {
    display: contents;
  }

  bw-product-review {
    .ratings-error {
      display: block;
    }

    [name="star"] {
      cursor: pointer;
    }

    ion-button {
      margin-top: 16px;
    }
  }

  ion-header {
    [name="close-circle-outline"] {
      cursor: pointer;
    }
  }

  app-cms-page {
    ol, ul {
      padding-left: 16px;
    }
  }

  app-customer-account-create-password {
    ion-list {
      max-width: 768px;
      margin-top: 16px;
      @media (min-width: map-get($grid-breakpoints, md)) {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  @media (min-width: map-get($grid-breakpoints, md)) {

    app-product {
      ion-content::part(scroll) {
        margin-top: 16px;
      }
    }

    ion-menu > ion-content::part(scroll) {
      margin-top: 0;
    }

    ion-modal ion-content::part(scroll) {
      margin-top: 0;
    }
  }

  ion-footer {
    border-top: solid 1px var(--ion-text-color);
    margin-top: 32px;
    margin-bottom: 16px;
    .footer-links-holder {
      padding-top: 32px;
    }
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
    ion-icon {
      margin-right: 0.5rem;
      top: 2px;
      position: relative;
    }
  }

  ion-popover {
    ion-button {
      color: var(--ion-text-color) !important;
    }
  }

  app-category > ion-content > ion-grid,
  app-search > ion-content > ion-grid {
    max-width: 1280px;
  }

  ion-icon:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

body {
  font-family: "Mundo Sans Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.5px;
  line-height: 24px;
  @media (max-width: map-get($grid-breakpoints, md)) {
    background-size: 100% 240px !important;
  }
}

h1, h2, h3, h4, h5, h6, .title-default {
  font-family: "Amsi Pro Condensed Black", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0px;
  line-height: initial;
}

h1 {
  text-transform: uppercase;
  font-size: 48px;
  @media (max-width: map-get($grid-breakpoints, xs)) {
    font-size: 32px;
  }

}

h2 {
  text-transform: uppercase;
}

div[data-content-type='video'] iframe, div[data-content-type='video'] video {
  height: 100%;
  width: 100%;
  min-height: 200px;
  @media (min-width: map-get($grid-breakpoints, md)) {
    min-height: 320px;
  }
}

.swiper {
  ion-icon {
    color: var(--ion-text-color) !important;
    font-size: 160px;
  }
  .swiper-button-prev::after, .swiper-button-next::after {
    content: '' !important;
  }
  .swiper-wrapper {
    height: 200px;
  }
  .swiper-slide {
    .product-item-photo {
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.product-filters {
  background: transparent !important;

  ion-list {
    background: transparent !important;
    display: none;

    ion-label {
      margin-left: 16px;
    }
  }
  ion-item {
    --background: transparent;
    --padding-start: 0;
    --border-style: none;
    cursor: pointer;
  }
  & > ion-item {
    --border-style: solid;
  }
  ion-label {
    color: var(--ion-background-color) !important;
    font-family:  "Mundo Sans Std", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  }
  ion-icon {
    color: var(--ion-background-color);
    margin-right: -16px;
  }

  ion-button {
    margin-top: 16px;
  }

  @media (min-width: map-get($grid-breakpoints, md)) {
    background: var(--ion-text-color) !important;
    padding: 0 16px 16px;
    margin-top: 10px;
    border-radius: 4px;
  }
}

ion-menu {
  ion-header {
    background: var(--bw-opaque-primary);
    ion-segment-button {
      color: var(--ion-background-color);
    }
  }
  ion-label {
    color: var(--ion-background-color) !important;
  }
  ion-checkbox {
    margin-left: 16px;
  }

  ion-list {
    background: transparent !important;
    ion-item {
      --background: transparent;
      --border-style: none;
      --padding-start: 0;
      text-indent: 16px;
      ion-icon {
        color: var(--ion-background-color);
      }
    }
    &.customer-account {
      ion-item {
        --border-style: solid;
      }
      .customer-account-login, .customer-account-register {
          margin-left: 16px;
          margin-right: 16px;
          ion-item {
            text-indent: 0;

            ion-checkbox {
              margin-left: 0;
              margin-right: 16px;
            }
          }
          ion-button {
            margin-top: 16px;
          }
      }
    }
    .customer-account-login, .customer-account-register, .customer-account-update {
      display: none;
    }
    ion-input {
      color: var(--ion-background-color) !important;
    }


  }

  ion-content {
    --background: var(--bw-opaque-primary);
    & > bw-menu-list > ion-list > ion-item {
      --border-style: solid;
    }
  }
}
.title-default {
  font-size: 32px;
  text-transform: uppercase;
}
.border-top {
  border-top: solid 1px var(--ion-background-color);
  margin-top: 16px;
}
.product-card-holder {
  @media (max-width: map-get($grid-breakpoints, md)) {
    padding: 0 4px 8px 0;
    &:nth-of-type(2n) {
      padding: 0 0 8px 4px;
    }
    ion-button {
      width: 36px !important;
      height: 36px !important;

      &::part(native) {
        padding: 8px !important;
      }
    }
  }
}
.product-detail-content {
  .product-card {
    ion-card-title {
      height: auto;
      max-height: unset;
    }
    ion-img {
      cursor: default;
    }
    video {
      height: unset;
      position: relative;
      top: 0;
    }
  }
}
.product-card {
  margin: 0;
  ion-item {
    --color: var(--ion-background-color);
  }
  ion-img {
    &::part(image) {
      border-radius:  5px 5px 0 0;
      margin: 1px;
      width: calc(100% - 2px);
    }
  }
  video {
    height: 160px;
    position: relative;
    top: 1px;
  }
  .contact-info {
    margin-top: 32px;
    ion-text {
      width: 100%;
    }
    font-size: 12px;
    a {
      display: contents;
    }
  }

  blockquote {
    position: relative;
    margin: 16px 0 0;
    padding: 0 32px;
  }

  blockquote:before,
  blockquote:after {
    position: absolute;
    font-size: 64px;
    width: 64px;
    height: 64px;
    font-family: "Amsi Pro Condensed Black", "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 0px;
    line-height: initial;
  }

  blockquote:before {
    content: '“';
    left: 0;
    top: -12px;
  }

  blockquote:after {
    content: '”';
    margin-left: 8px
  }

  ion-card-title {
    &:hover {
      overflow: visible;
    }
  }
  ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --background: var(--ion-text-color);
    --inner-border-width: 0;
    ion-radio {
      margin: -4px 8px 0 4px;
    }
    ion-icon {
      margin: -4px 8px 0 0;
      color: var(--ion-background-color) !important;
    }

    ion-label {
      --color: var(--ion-background-color) !important;
    }
  }
  .progress-bar-determinate {
    --progress-background: var(--ion-color-warning);
    --background: var(--ion-color-medium);
  }
  ion-card-content {
    padding-bottom: 0;
  }
  ion-toolbar {
    [name="heart"], [name="heart-outline"] {
      color: var(--ion-color-danger);
    }
    [name="star"], [name="star-outline"] {
      color: var(--ion-color-warning);
    }
    [name="checkmark-circle"], [name="checkmark-circle-outline"] {
      color: var(--ion-color-primary);
    }
    [name="download"], [name="download-outline"] {
      color: var(--ion-color-success);
    }
  }
}
.product-buttons {
  ion-spinner {
    width: 18px;
    height: 18px;
  }
}

.mobile-menu {

  ion-label {
    font-family:  "Mundo Sans Std", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  }
  &.menu-level-1 {
    .active::before {
      content: "";
      border-left: solid 5px var(--ion-color-primary);
      display: block;
      height: 100%;
      width: 5px;
      position: absolute;
    }
  }
  &.menu-level-2, &.menu-level-3 {
    .active::before {
      content: unset;
    }
    .active {
      ion-label, ion-icon {
        font-weight: bold;
        color: var(--ion-color-primary) !important;
      }
    }
  }

}
.menu-link {
  font-size: 12px !important;
  color: var(--ion-color-primary) !important;
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
}
bw-header {
  height: 240px;
  background-position: 50% 0;
  background-size: cover;
  @media (max-width: map-get($grid-breakpoints, md)) {
    height: 160px;
  }

  ion-header {
    max-width: 1280px;
    margin: 0 auto;
  }
}


.page-title-holder {
  z-index: 0;
  position: absolute;
  top: 35px;
  height: 170px;
  color: var(--ion-text-color);
  text-transform: uppercase;

  .page-title-inner {
    max-width: 1280px;
    margin: 0 auto;

  }
  .title-holder {
    position: relative;
    height: fit-content;
    width: fit-content;
    text-align: center;
    top: 20px;
    @media (max-width: map-get($grid-breakpoints, xl)) {
      text-indent: 20px;
      height: 70px;
      top: -20px;
    }
  }
    strong {
      position: relative;
      z-index: 1;
      text-transform: capitalize;
      font-weight: 400;
      font-family: "Avallon Alt", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
      font-size: 118px;
      line-height: 118px;
      color: var(--ion-color-warning);
      display: block;
      margin-top: -24px;
      &:first-child {
        margin-top: -8px;
        @media (max-width: map-get($grid-breakpoints, xl)) {
          margin-top: 24px;
        }
      }
      @media (max-width: map-get($grid-breakpoints, xl)) {
        font-size: 48px;
        line-height: 48px;
        margin-top: -16px;
      }
    }
    span {
      font-family: "Amsi Pro Condensed Black", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
      display: block;
      font-size: 64px;
      margin-top: 24px;
      min-height: 32px;
      &:last-child {
        margin-top: -8px;
        @media (max-width: map-get($grid-breakpoints, xl)) {
          margin-top: -8px;
        }
      }
      @media (max-width: map-get($grid-breakpoints, xl)) {
        font-size: 32px;
        margin-top: 32px;
      }
    }

    padding-left: 0 !important;
    margin-top: 10px;

    @media (max-width: map-get($grid-breakpoints, xl)) {
      padding-left: 8px !important;
      line-height: 32px;
    }
    padding-right: 0 !important;
    @media (min-width: map-get($grid-breakpoints, md)) {
      font-size: 80px;
      margin-top: 0;
    }
  @media (max-width: map-get($grid-breakpoints, md)) {
    height: 90px;
  }
  ion-toolbar {
    max-width: 1280px;
    margin: 0 auto;
    --border-width: 0 !important;
  }

  &::after {
    background-image: none;
  }
}

bw-menu-list {
  width: 100%;
  background: var(--ion-text-color);
  z-index: 10;
  .menu-item {
    cursor: pointer;
  }
}
.plt-ios, .plt-android {
  .desktop-menu {
    display: none;
  }
}

.desktop-menu {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  background: var(--ion-text-color);

  .desktop-menu-category-image {
    display: none;
    position: absolute;
    right: 0;
    top: 48px;
    width: 480px;
    height: 320px;
    z-index: 3;
    margin: 32px;
    background-repeat: no-repeat;
  }

  &.menu-level-2 {
    background: var(--bw-opaque-primary);
  }
  &.menu-level-3 {
    background: transparent;
  }

  padding: 0;
  contain: unset;

  & > ion-item {
    --background: transparent;
    --border-style: none;
    float: left;
    position: relative;

    &.active {
      --background: var(--ion-color-primary);
      ion-label {
        color: var(--ion-text-color) !important;
      }
    }

    &:hover {
      --background: var(--ion-color-primary);
      ion-label {
        color: var(--ion-text-color) !important;
      }
    }
    ion-icon {
      display: none;
    }

    ion-label {
      font-family: "Amsi Pro Condensed Bold" !important;
      letter-spacing: 0px;
      line-height: initial;
      text-transform: uppercase;
      color: var(--ion-background-color) !important;
      &:hover {
        color: var(--ion-text-color) !important;
      }
    }
  }

  & .menu-level-2, & .menu-level-3, & .menu-level-4 {
    display: none;
    position: absolute;
    top: 48px;
    left: 0;
    margin-left: 0 !important;
    ion-item {
      float: none;
    }
  }
  & .menu-level-2 {
    min-height: 532px;
    ion-item {
      width: 240px;
    }
  }
  & .menu-level-3 {
    left: 240px;
    top: 0;
  }
  & .menu-level-4 {
    left: 240px;
    top: 0;
    background: transparent;
  }
}

.cart-item-count {
  position: absolute;
  bottom: 6px;
  font-size: 12px;
  font-weight: 800;
  color: #000;
}

.product-detail {
  .ion-page {
    justify-content: normal;
  }
  .product-card {
    margin: 10px;
    ion-icon[slot="end"] {
      margin-right: -16px;
    }
    .event-reserve {
      margin-left: 24px;
      ion-icon {
        margin-top: -4px;
      }
      ion-spinner {
        margin-right: 8px;
      }
    }
    ion-list {
      background: none;
    }
  }
}

.contact-form-toggle {
  cursor: pointer;
  text-decoration: underline;
  color: var(--ion-color-primary);
}

.contact-form-content {
  ion-button {
    margin-top: 16px;
  }
}

img {
  &.pagebuilder-mobile-hidden {
    @media (max-width: map-get($grid-breakpoints, md)) {
      display: none;
    }
  }

  &.pagebuilder-mobile-only {
    display: none;
    @media (max-width: map-get($grid-breakpoints, md)) {
      display: inline;
    }
  }
}

.pagebuilder-button-primary {
  text-decoration: none;
  color: var(--ion-text-color);
}

[data-appearance="align-center"], [data-appearance="align-bottom"] {
  display: flex;
  flex-direction: column;
}

[data-appearance="align-bottom"] {
  align-self: flex-end;
  justify-content: flex-end;
}

[data-appearance="contained"] {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;

}

[data-appearance="full-width"] {
  ion-col {
    max-width: 1280px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
figure[data-content-type="image"] {
  margin: 0;
  &[data-appearance="full-width"] {
    img {
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.cms-placeholder {
  margin-left: auto;
  margin-right: auto;
  .mobile-reverse {
    @media (max-width: map-get($grid-breakpoints, xl)) {
      flex-direction: column-reverse;
    }
  }
}

.logo {
  margin-top: 8px;
  margin-left: 8px;
  @media (min-width: map-get($grid-breakpoints, md)) {
    margin-top: 16px;
  }
}

.no-results {
  padding: 32px 16px;
}

.search-suggestions {
  padding-left: 16px;
}

.product-image-photo, .product-card ion-img::part(image) {
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -10px;
    left: 0;
    height: calc(100% + 10px);
    width: 100%;
    background-color: rgb(230, 230, 230);
  }
  &:after {
    content: "";
    background: url("/assets/icons/icon-512x512.png") no-repeat;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-size: 100% 100%;
    opacity: 0.2;
  }
}

.simple-info {
  font-size: 12px;
  color: var(--ion-color-primary);
  position: absolute;
  top: 32px;
}
.event-date-icon {
  cursor: pointer;
}

.slot-picker {
  ion-list {
    background: none;
    ion-item::part(native) {
      padding-left: 0;
    }
    ion-label {
      color: var(--ion-background-color) !important;
      &.slot-title {
        font-weight: bold;
        font-family: "Amsi Pro Condensed Black", "Helvetica Neue", Helvetica, Arial, sans-serif;
        text-transform: uppercase;
      }
    }
    ion-radio {
      margin-right: 1rem;
    }
  }
}

.msa[data-content-type="heading"] {
  &::after {
    content: '';
    display: block;
    position: relative;
    width: 60px;
    height: 3px;
    margin: 8px auto;
  }
  &.green::after {
    background-color: var(--ion-color-success);
  }
  &.yellow::after {
    background-color: var(--ion-color-warning);
  }
  &.red::after {
    background-color: var(--ion-color-danger);
  }
  &.blue::after {
    background-color: var(--ion-color-primary);
  }
}

.msa[data-content-type="divider"] {
  hr {
    border-width: 0 !important;
    width: 60px !important;
  }
  &.green hr {
    border-top: 3px solid var(--ion-color-success) !important;
  }
  &.yellow hr {
    border-top: 3px solid var(--ion-color-warning) !important;
  }
  &.red hr {
    border-top: 3px solid var(--ion-color-danger) !important;
  }
  &.blue hr {
    border-top: 3px solid var(--ion-color-primary) !important;
  }
}

.msa[data-content-type="text"] {
  &.double-line {
    width: fit-content;
    margin: 0 auto;
    position: relative;
    &::after {
      content: "";
      border-top: solid 1px var(--ion-text-color);
      width: 100%;
      display: block;
      position: absolute;
      bottom: 12px;
      z-index: -1;
    }
    & span:last-of-type {
      background-color: var(--ion-background-color);
      padding-left: 4px;
    }
  }
}

h1.stylized {
  strong {
    position: relative;
    z-index: 1;
    text-transform: capitalize;
    font-weight: 400;
    font-family: "Avallon Alt", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-size: 118px;
    line-height: 118px;
    color: var(--ion-color-warning);
    display: block;
    margin-top: -40px;
    &:first-child {
      margin-top: -8px;
      @media (max-width: map-get($grid-breakpoints, xl)) {
        margin-top: 24px;
      }
    }
    @media (max-width: map-get($grid-breakpoints, xl)) {
      font-size: 48px;
      line-height: 48px;
      margin-top: -16px;
    }
  }
  span {
    font-family: "Amsi Pro Condensed Black", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    display: block;
    font-size: 64px;
    margin-top: 24px;
    min-height: 32px;
    &:last-child {
      margin-top: -8px;
      @media (max-width: map-get($grid-breakpoints, xl)) {
        margin-top: -8px;
      }
    }
    @media (max-width: map-get($grid-breakpoints, xl)) {
      font-size: 32px;
      margin-top: 32px;
    }
  }
}

.maintenance {
  max-width: 1280px;
  margin: 0 auto;
  h1 {
    font-family: "Amsi Pro Condensed Black", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-size: 32px !important;
    letter-spacing: 0px;
    line-height: initial;
  }
}

.listing-utils {
  ion-button {
    min-width: 2.5rem;
    margin-left: 0.5rem;
  }
}
details {
  summary {
    font-family: "Amsi Pro Condensed Black", "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 0px;
    line-height: initial;
    font-size: 24px;
    --color: var(--ion-background-color);
    cursor: pointer;
  }
}

.logo-wrapper {
  max-width: 1280px;
  margin: 0 auto;
  position: sticky;
  width: 100%;
  animation: fadeIn 5s;

  img {
    position: absolute;
    right: 1rem;
    top: -105px;
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  70% { opacity: 0; }
  100% { opacity: 1; }
}

@media (max-width: map-get($grid-breakpoints, md)) {
 
  .logo-wrapper {
    max-width: 1280px;
    margin: 0 auto;
    position: sticky;
    width: 100%;
    animation: fadeIn 0s;

    img {
      position: absolute;
      right: auto;
      left: 1rem;
      top: -144px;
      height: 56px;
    }
  }
  .page-title-holder .title-holder {
    top: 0.5rem;
    text-align: left;
  }
}